<template>
  <div>
    <h3 class="mb-5">Select Customer</h3>
    <v-autocomplete
      v-model="customer"
      label="Select customer..."
      outlined
      dense
      :items="customers"
      item-text="customer_name"
      return-object
      @change="
        $emit('passCustomerSelect', $event);
        $emit('passCreditNotes', unfiltered_credit_notes);
      "
    >
    </v-autocomplete>
  </div>
</template>
<script>
import db from "../../../../../components/firebaseInit";
export default {
  name: "CreditNoteRefundsAutocomplete",
  data() {
    return {
      customer: null,
      customers: [],
      unfiltered_credit_notes: [],
    };
  },
  created() {
    this.getCustomers();
  },
  methods: {
    async getCustomers() {
      const docRef = db.collection("customer_credit_notes");
      const creditNotesSnapshot = await docRef.get();
      this.unfiltered_credit_notes = creditNotesSnapshot.docs.map((doc) => ({
        ...doc.data(),
        customer_name: doc.data().customer.customer_name,
        reference_number: doc.data().invoice
          ? doc.data().invoice.invoice_number
          : doc.data().sales_order.order_number,
        old_amount_refunded: doc.data().credit_note_amount_refunded,
        allocatedPortion: 0,
      }));

      this.customers = creditNotesSnapshot.docs
        .reduce((list, doc) => {
          const data = doc.data();
          // filter out customers that have crdit notes that are
          // already fully refunded
          return data.total_credited_amount === data.credit_note_amount_refunded
            ? list
            : list.concat({ ...data.customer });
        }, [])
        .sort((a, b) => (a.customer_name > b.customer_name ? 1 : -1));
    },
  },
};
</script>